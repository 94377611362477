import {createSelector} from "reselect"
import pendingRequestsSelector from "./pendingRequestsSelector"
import requestIsPending from "./helpers/requestIsPending"
import {API_URL_DIGITAL_GIVEAWAY_LINK} from "../../../constants/apiUrls"

const digitalGiveawayLinkRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_DIGITAL_GIVEAWAY_LINK),
)

export default digitalGiveawayLinkRequestIsPendingSelector
