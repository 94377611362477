import {FunctionComponent, useEffect} from 'react'
import {Params} from '../../redux/selectors/initRouterParamsSelector'
import {ROUTE_OVERVIEW} from '../../constants/routes'
import NavTarget from '../../entities/NavTarget'
import {push} from 'connected-react-router'
import {useDispatch} from 'react-redux'
import {setShouldNavigateToTarget} from '../../redux/actions/app/navigation.actions'
import NavLink from '../../entities/NavLink'

export type Props = {
    shouldNavigateToTarget: boolean
    requestsArePending: boolean
    navigationTargets: NavTarget[]
    routerParams: Params
    availableNavLinks: NavLink[]
}

const TargetNavigation: FunctionComponent<Props> = (props) => {
    const { shouldNavigateToTarget, requestsArePending, navigationTargets, routerParams, availableNavLinks} = props
    const dispatch = useDispatch()

    useEffect(() => {

        if (shouldNavigateToTarget && !requestsArePending) {

            const targetFromRouter = routerParams?.target

            let targetToNavigateTo = ROUTE_OVERVIEW
            if (navigationTargets && targetFromRouter) {
                const navTarget = navigationTargets.find((target: NavTarget) => target.id === targetFromRouter)
                const navLink = availableNavLinks.find(link => link.id === navTarget?.value)
                if (navLink) {
                    targetToNavigateTo = navLink.url
                }
            }
            dispatch(setShouldNavigateToTarget(false))
            dispatch(push(targetToNavigateTo))
        }

    }, [dispatch, requestsArePending, navigationTargets, routerParams?.target, shouldNavigateToTarget, availableNavLinks])

    return null
}

export default TargetNavigation
