import {createReducer} from "typesafe-actions"
import {
    resetDigitalGiveawayUrl,
    setDigitalGiveawayUrl,
} from "../actions/app/digitalGiveaway.actions"

export const defaultState: string = ''

const digitalGiveawayLinkReducer = createReducer(defaultState)
    .handleAction(setDigitalGiveawayUrl, (state, action) => action.payload)
    .handleAction(resetDigitalGiveawayUrl, (() => defaultState))

export default digitalGiveawayLinkReducer
