import { createSelector } from 'reselect'
import entryDataSelector from '../entryDataSelector'
import {BuildabilityType} from '../../../entities/BuildabilityType'

const buildabilityTypesSelector = createSelector(
    entryDataSelector,
    (entryData): BuildabilityType[] => entryData.buildabilityTypes,
)

export default buildabilityTypesSelector
