import { createSelector } from 'reselect'
import configurationIsBuildableSelector from './configuration/configurationIsBuildableSelector'
import entryDataSelector from './entryDataSelector'
import orderButtonsAreActiveSelector from "./feature-scope/orderButtonsAreActiveSelector"

const checkoutIsEnabledSelector = createSelector(
    configurationIsBuildableSelector,
    orderButtonsAreActiveSelector,
    entryDataSelector,
    (configurationIsBuildable, orderButtonsAreActive, entryData): boolean => (
        configurationIsBuildable
            && orderButtonsAreActive
            && entryData.links && entryData.links.find(link => link.id === "orderUrl") !== undefined
    ),
)

export default checkoutIsEnabledSelector
