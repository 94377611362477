import {Action, createAction, createAsyncAction} from "typesafe-actions"
import VehicleCode from "../../../entities/VehicleCode"
import ErrorType from "../../../entities/ErrorType"
import DigitalGiveawayLink from "../../../entities/DigitalGiveawayLink"
import MetaAsyncHandler from "../../../entities/MetaAsyncHandler"
import DigitalGiveawayLinkUrl from "../../../entities/DigitalGiveawayLinkUrl"

export const openDigitalGiveawayUrl = createAction('CMD / DigitalGeveawayLink / open')<string | undefined>()

export const digitalGiveawayGotVehicleCode = createAction('EVT / DigitalGeveawayLink / got vehicleCode')<{ vehicleCode: VehicleCode }>()

export const createDigitalGiveawayUrl = createAction('CMD / DigitalGeveawayLink / create')<{ flightCamera: boolean; dealerId: string }>()

export const loadDigitalGiveawayUrl = createAction('CMD / DigitalGeveawayLink / load')<DigitalGiveawayLink, MetaAsyncHandler>()

export const loadDigitalGiveawayUrlAsync = createAsyncAction(
    'EVT / DigitalGeveawayLink / load request',
    'EVT / DigitalGeveawayLink / load success',
    'EVT / DigitalGeveawayLink / load failure',
)<undefined, DigitalGiveawayLink, Error>()

export const setDigitalGiveawayUrl = createAction('DOC / DigitalGeveawayLink / set state')<DigitalGiveawayLinkUrl>()
export const resetDigitalGiveawayUrl = createAction('DOC / DigitalGeveawayLink / reset state')()

export const digitalGiveawayUrlIsLoaded = createAction('CMD / DigitalGeveawayLink / loaded')<{ url: string} | undefined>()

export const digitalGiveawayUrlCreationFailed = createAction('EVT / DigitalGeveawayLink / digital giveaway link creation failed')<{ failureAction: Function; error: Error; type: ErrorType; url: string; message: string}, { causedBy: Action }>()
