import { connect, MapStateToProps } from 'react-redux'
import renderImagesSelector from '../../redux/selectors/renderImagesSelector'
import activeRenderImgIdxSelector from '../../redux/selectors/activeRenderImgIdxSelector'
import visualizationRequestIsPendingSelector from '../../redux/selectors/pending-request/visualizationRequestIsPendingSelector'
import { setActiveRenderImgIdx } from '../../redux/actions/app/renderImages.actions'
import { Props } from './RenderImagesPreview'
import renderImagesPreviewIsHiddenSelector from '../../redux/selectors/feature-scope/renderImagesPreviewIsHiddenSelector'
import renderImagesOrderingSelector from "../../redux/selectors/render-image/renderImagesOrderingSelector"

type StateProps = Pick<Props, 'renderImages' | 'activeRenderImgIdx' | 'isLoading' | 'isHidden' | 'renderImagesOrdering'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    renderImages: renderImagesSelector(state),
    activeRenderImgIdx: activeRenderImgIdxSelector(state),
    isLoading: visualizationRequestIsPendingSelector(state),
    isHidden: renderImagesPreviewIsHiddenSelector(state),
    renderImagesOrdering: renderImagesOrderingSelector(state),
})

type DispatchProps = Pick<Props, 'setActiveRenderImgIdx'>

const mapDispatchToProps: DispatchProps = {
    setActiveRenderImgIdx,
}

export default connect(mapStateToProps, mapDispatchToProps)
