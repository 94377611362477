import React, {FunctionComponent, useEffect} from 'react'

import { useDispatch } from 'react-redux'
import AudiExclusiveDataState from '../../entities/AudiExclusiveDataState'
import { resetAudiExclusiveDataState, storeAudiExclusiveSelection } from '../../redux/actions/app/audiExclusiveApp.actions'
import { getConvertedAudiExclusiveSelection } from '../../redux/middleware/app/helpers/getConvertedAudiExclusiveSelection'
import Visualization from '../../entities/Visualization'

export type Props = {
    audiExclusiveDataState: AudiExclusiveDataState
    visualization: Visualization
    hasAudiExclusiveEquipments: boolean
    modelId: string
    currency: string
}

const formatPrice = (price) => Number(price?.replace(/[^0-9.,]+/g, '').replace(',', '').replace('.', '')) / 100
const formatModelId = (modelId) => modelId.substring(0, 3)

const AudiExclusiveApp: FunctionComponent<Props> = ({audiExclusiveDataState, visualization, hasAudiExclusiveEquipments, modelId, currency}) => {
    const dispatch = useDispatch()
    const { audiExclusiveSelectedEquipment: currentEquipment, shouldOpenAudiExclusiveApp, language } = audiExclusiveDataState

    useEffect(() => {
        const aeConfigurator = document.querySelector('ae-configurator')

        const handleSaveEvent = (event) => {
            const hasSelection = event.detail?.selection && Object.keys(event.detail.selection).length > 0

            if (hasSelection) {
                const audiExclusiveSelection = getConvertedAudiExclusiveSelection(event.detail)
                dispatch(storeAudiExclusiveSelection(audiExclusiveSelection))
            }

            dispatch(resetAudiExclusiveDataState())
        }

        if (aeConfigurator) {
            aeConfigurator.addEventListener('save', handleSaveEvent)
        }

        return () => aeConfigurator?.removeEventListener('save', handleSaveEvent)
    }, [dispatch, audiExclusiveDataState, visualization])

    return (
        <>
            {visualization.aveString && hasAudiExclusiveEquipments && (
                <div>
                    <ae-configurator
                        key={currentEquipment?.id ?? 'closed'}
                        pr-string={visualization.aveString}
                        data-testid={`ae-menu-container-${currentEquipment.evaKey}`}
                        package-name={currentEquipment.name}
                        car-model={formatModelId(modelId)}
                        currency={currency}
                        price={formatPrice(currentEquipment.price)}
                        package-code={currentEquipment.evaKey}
                        show={shouldOpenAudiExclusiveApp}
                        locale={language}/>
                </div>
            )}
        </>
    )
}

export default AudiExclusiveApp
