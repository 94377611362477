import {createAction, createAsyncAction} from 'typesafe-actions'
import {BuildabilityType} from '../../../entities/BuildabilityType'
import BuildabilityTypeResponse from '../../../entities/BuildabilityTypeResponse'

export const changeBuildabilityType = createAction('CMD / BuildabilityType / set type')<BuildabilityType>()

export const setCurrentBuildabilityType = createAction('CMD / BuildabilityType / set buildability type')<string>()

export const fetchBuildabilityType = createAction('CMD / BuidabilityType / fetch')()

export const changeBuildabilityTypeAsync = createAsyncAction(
    'EVT / BuildabilityType / set request',
    'EVT / BuildabilityType / set success',
    'EVT / BuildabilityType / set failure',
)<BuildabilityType, void, Error>()

export const fetchBuildabilityTypeAsync = createAsyncAction(
    'EVT / BuildabilityType / fetch request',
    'EVT / BuildabilityType / fetch success',
    'EVT / BuildabilityType / fetch failure',
)<undefined, BuildabilityTypeResponse, Error>()
