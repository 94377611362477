import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import CarlineSelection from '../carline-selection'
import {loadConfigurationByVehicleCode} from '../../redux/actions/app/vehicleCode.actions'
import {useDispatch} from 'react-redux'
import {loadConfigurationByPrString} from '../../redux/actions/app/prString.actions'

const BasePathHandler: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const audiCode = queryParams.get('audiCode') || queryParams.get('vehicleCode')
        const prString = queryParams.get('prString')

        if (audiCode) {
            dispatch(loadConfigurationByVehicleCode(audiCode))
        } else if (prString) {
            dispatch(loadConfigurationByPrString(prString))
        }
    }, [location, dispatch])

    return <CarlineSelection/>
}

export default BasePathHandler
