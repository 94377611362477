import {Middleware} from 'redux'
import {isActionOf} from 'typesafe-actions'
import {loadConfigurationByPrStringApiUrl} from '../../../constants/apiUrls'
import {ApiRequest} from '../../apiRequest'
import {
    loadConfigurationByPrString,
    loadConfigurationByPrStringAsync,
} from '../../actions/app/prString.actions'
import {fetchConfiguration} from '../../actions/app/configuration.actions'
import {fetchCart} from '../../actions/app/cart.actions'
import {fetchEquipmentGroups} from '../../actions/app/equipmentGroups.actions'
import {fetchVisualization} from '../../actions/app/visualization.actions'
import {setShouldNavigateToTarget} from '../../actions/app/navigation.actions'

const prStringMiddleware =
    (apiRequest: ApiRequest): Middleware =>
        (store) =>
            (next) =>
                (action) => {
                    next(action)
                    const {dispatch} = store

                    if (isActionOf(loadConfigurationByPrString, action)) {
                        const prString = action.payload

                        apiRequest(
                            {
                                options: {
                                    url: loadConfigurationByPrStringApiUrl(decodeURI(prString)),
                                    method: 'POST',
                                },
                                asyncActions: loadConfigurationByPrStringAsync,
                                causedBy: action,
                            },
                            dispatch,
                        )
                    }

                    if (isActionOf(loadConfigurationByPrStringAsync.success, action)) {
                        const {status} = action.payload

                        if (status === 'OK') {
                            dispatch(fetchConfiguration())
                            dispatch(fetchCart())
                            dispatch(fetchEquipmentGroups(undefined))
                            dispatch(fetchVisualization())
                        }
                        dispatch(setShouldNavigateToTarget(true))
                    }
                }
export default prStringMiddleware
