import {ApiRequest} from '../../apiRequest'
import {isActionOf} from 'typesafe-actions'
import {
    changeBuildabilityType,
    changeBuildabilityTypeAsync,
    fetchBuildabilityType,
    fetchBuildabilityTypeAsync,
    setCurrentBuildabilityType,
} from '../../actions/app/buildabilitySwitcher.actions'
import {
    getChangeBuildabilityTypeApiUrl,
    getFetchBuildabilityTypeApiUrl,
} from '../../../constants/apiUrls'

const buildabilitySwitcherMiddleware = (apiRequest: ApiRequest) => (store) => (next) => (action) => {
    next(action)
    const {dispatch} = store

    if (isActionOf(changeBuildabilityType, action)) {
        const buildabilityType = action.payload

        apiRequest({
            options: {
                url: getChangeBuildabilityTypeApiUrl(buildabilityType),
                method: 'POST',
            },
            asyncActions: changeBuildabilityTypeAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(changeBuildabilityTypeAsync.success, action)) {
        dispatch(fetchBuildabilityType())
    }

    if (isActionOf(fetchBuildabilityType, action)) {

        apiRequest({
            options: {
                url: getFetchBuildabilityTypeApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchBuildabilityTypeAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchBuildabilityTypeAsync.success, action)) {
        const {buildabilityType} = action.payload
        dispatch(setCurrentBuildabilityType(buildabilityType))
    }

}

export default buildabilitySwitcherMiddleware
