import React, {FunctionComponent, useState} from 'react'
import { Spinner } from '../../base-components'
import useTranslation from '../../hooks/useTranslation'
import Modal, { ModalClosingButton } from '../modal'
import style from './digitalGiveawayModal.css'
import CopyIcon from '../../assets/media/icons/copy-link.svg'
import DoneIcon from '../../assets/media/icons/check.svg'
import {Input} from "../form"

export type Props = {
    createDigitalGiveawayUrl: Function
    resetDigitalGiveawayUrl: Function
    openModalState: [boolean, Function]
    isLoading: boolean
    urlLink: string
}

const DigitalGiveawayModal: FunctionComponent<Props> = (props) => {
    const {
        openModalState,
        isLoading,
        createDigitalGiveawayUrl,
        resetDigitalGiveawayUrl,
        urlLink,
    } = props
    const { t } = useTranslation()
    const [copied, setCopied] = useState(false)
    const [dealerId, setDealerId] = useState("")
    const [modalIsOpen, setModalIsOpen] = openModalState

    const closeHandler = (): void => {
        setCopied(false)
        resetDigitalGiveawayUrl()
        setModalIsOpen(false)
    }

    const clickHandler = (flightCamera: boolean): void => {
        createDigitalGiveawayUrl({flightCamera:flightCamera, dealerId:dealerId})
    }

    const copyClickHandler = (): void => {
        setCopied(true)
        navigator.clipboard.writeText(urlLink)
    }

    const getCopiedClass = (): string => {
        return copied ? style.copied : ""
    }

    const getCopiedIcon = () => {
        return copied ? <DoneIcon className={style.copiedSvg}/> : <CopyIcon/>
    }

    const content = isLoading ? (
        <div className={style.spinnerContainer}>
            <Spinner />
        </div>
    ) : urlLink ? (
            <div className={style.linkContent}>
                <div className={style.urlLink}>{urlLink}</div>
                <div className={style.urlButton}>
                    <button title={t(`digitalGiveaway.linkCopy`)} onClick={copyClickHandler} className={`${style.urlCopyButton} ${getCopiedClass()}`}>
                        {getCopiedIcon()}
                    </button>
                </div>
                <div className={style.info}>
                    {t(`digitalGiveaway.info`)}
                </div>
            </div>
        )
        : (
            <div className={style.mainContent}>
                <div className={style.dealerWrapper}>
                    <Input
                        value={dealerId}
                        onChange={(event) => setDealerId(event.target.value)}
                        minLength={9}
                        placeholder={t(`digitalGiveaway.modalGenerateDealerId`)}
                        className={style.dealerInput}
                        data-cy="input-digital-giveaway-dealer-id"
                    />
                </div>
                <button
                    className={style.button}
                    onClick={() => clickHandler(true)}>
                    {t(`digitalGiveaway.modalGenerateWith`)}
                </button>
                <button
                    className={style.button}
                    onClick={() => clickHandler(false)}>
                    {t(`digitalGiveaway.modalGenerateWithout`)}
                </button>
            </div>
        )

    const headerContent = urlLink ?
        t(`digitalGiveaway.modalLoadedHeader`)
        : t(`digitalGiveaway.modalGenerateHeader`)

    return (
        <Modal isVisible={modalIsOpen} onClose={closeHandler}>
            <ModalClosingButton onClick={closeHandler} />
            <section className={style.container}>
                <h2 className={style.headline}>{headerContent}</h2>
                {content}
            </section>
        </Modal>
    )
}

export default DigitalGiveawayModal
