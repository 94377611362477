import { createReducer } from 'typesafe-actions'
import Cart from '../../entities/Cart'
import { setCartState, resetCartState } from '../actions/app/cart.actions'

export type CartState = Cart

export const defaultState: CartState = {
    priceViewFlag: 'BRUTTO',
    cartName: '',
    prices: [],
}

const cartReducer = createReducer(defaultState)
    .handleAction(setCartState, (state, action) => action.payload)
    .handleAction(resetCartState, () => defaultState)

export default cartReducer
