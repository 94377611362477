import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import camelCase from 'lodash.camelcase'
import classNames from 'classnames'
import useTranslation from '../../hooks/useTranslation'
import Prices, {Price} from '../../entities/Prices'
import VatIncludedSwitch from '../vat-inclusion-switch'
import style from './cartboxPrices.css'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import NoteTable from '../note-table'
import isEmpty from 'lodash/isEmpty'

export type Props = {
    prices: Prices
    isHidden: boolean
    openInfoModal: Function
    configIsBuildable?: boolean
}

const CartboxPrices: FunctionComponent<Props> = (props) => {
    const { prices, isHidden, openInfoModal, configIsBuildable } = props
    const { t } = useTranslation()

    if (isHidden) {
        return null
    }

    const createRowElement = (price: Price) => {
        const {
            id, value, isSum, hasSeparator, note, configNotBuildableValue, hideIfConfigNotBuildable,isGroupedItem,
        } = price

        if (hideIfConfigNotBuildable && !configIsBuildable) {
            return
        }

        let displayValue = value
        let vatIncluded = isSum ? <VatIncludedSwitch /> : null

        if(configNotBuildableValue && !configIsBuildable) {
            displayValue = t(`price.${camelCase(configNotBuildableValue)}`)
            vatIncluded = null
        }

        const translatedTitle = t(`price.${camelCase(id)}`)
        const title = translatedTitle === `price.${camelCase(id)}` ? id : translatedTitle
        const markedTitle = price.mark ? `${title}${price.mark}` : title

        const rowStyle = classNames(style.row, {
            [style.rowSum]: isSum && !isGroupedItem,
            [style.rowSeparator]: hasSeparator,
            [style.rowDiscount]: note,
        })

        const titleClass = note ? style.shortPriceTitle : style.priceTitle

        const openDiscountModal = (): void => {
            const tabs = [
                {
                    id,
                    label: '',
                    content: (
                        <NoteTable data={note} key={id} />
                    ),
                },
            ]

            openInfoModal({
                title,
                wheelEfficiencyLabelIsEnabled: false,
                media: [],
                tabs,
            })
        }

        const infoButtonElement = note ? (
            <InfoButton openModal={openDiscountModal} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                <InfoI isForced={false} />
            </InfoButton>
        ) : null

        return (<>
                <div key={id} className={rowStyle}>
                <span className={titleClass}>
                    {markedTitle}
                </span>
                    {infoButtonElement}
                    <span className={style.price}>
                    <Markup content={displayValue}/>
                </span>
                </div>
                {vatIncluded}
            </>
        )
    }

    const rowElements = prices
        .filter(price => !price?.isGroupedItem)
        .map((price) => {
            return createRowElement(price)
        })

    const createGroupedElements = () => {
        const groupedRowItems = prices
            .filter(price => price?.isGroupedItem)

        if(isEmpty(groupedRowItems)) {
            return null
        }

        return (
            <>
                <div className={style.groupRowSeparator}>
                    {groupedRowItems.map(price => createRowElement(price))}
                </div>
            </>
        )
    }

    const groupedRowElements = createGroupedElements()

    return (
        <div className={style.container} data-cy="cart-pricing">
            {rowElements}
            {groupedRowElements}
        </div>
    )
}

export default CartboxPrices
