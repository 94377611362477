import React, { FunctionComponent } from 'react'
import { Spinner } from '../../base-components/spinner'
import Modal from '../modal'
import style from './loadingModal.css'

export type Props = {
    isVisible: boolean
}

const LoadingModal: FunctionComponent<Props> = (props) => {
    const { isVisible } = props

    if (!isVisible) {
        return null
    }

    return (
        <Modal
            isVisible={isVisible}
            containerClassName={style.modalContainer}
            contentClassName={style.modalContent}
            isFixed={false}>
            <Spinner />
        </Modal>
    )
}

export default LoadingModal
