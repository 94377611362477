import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    createVehicleCode,
    setVehicleCodeState,
} from '../../actions/app/vehicleCode.actions'
import {
    openDigitalGiveawayUrl,
    createDigitalGiveawayUrl,
    digitalGiveawayGotVehicleCode,
    loadDigitalGiveawayUrl,
    digitalGiveawayUrlIsLoaded,
    digitalGiveawayUrlCreationFailed, loadDigitalGiveawayUrlAsync, setDigitalGiveawayUrl,
} from '../../actions/app/digitalGiveaway.actions'
import { createNotification } from '../../actions/app/notification.actions'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'
import {
    API_URL_VEHICLECODE,
    API_URL_DIGITAL_GIVEAWAY_LINK,
    getDigitalGiveawayLinkUrl,
} from '../../../constants/apiUrls'
import vehicleCodeSelector from "../../selectors/vehicleCodeSelector"
import { ApiRequest } from "../../apiRequest"

const digitalGiveawayMiddleware = (
    apiRequest: ApiRequest,
    console: Console,
): Middleware => (store) => (next) => (action) => { /* eslint-disable-line consistent-return */
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(openDigitalGiveawayUrl, action)) {
        const vehicleCodeValue = vehicleCodeSelector(getState())
        if(vehicleCodeValue){
            dispatch(digitalGiveawayGotVehicleCode({vehicleCode: vehicleCodeValue}))
        }else{
            dispatch(createVehicleCode(
                undefined,
                {
                    onSuccess: digitalGiveawayGotVehicleCode,
                    onFailure: digitalGiveawayUrlCreationFailed,
                },
            ))
            dispatch(addPendingRequest(API_URL_VEHICLECODE, { causedBy: openDigitalGiveawayUrl(undefined) }))
        }
    }

    if (isActionOf(digitalGiveawayGotVehicleCode, action)) {
        dispatch(setVehicleCodeState(action.payload.vehicleCode))
        dispatch(removePendingRequest(API_URL_VEHICLECODE, { causedBy: openDigitalGiveawayUrl(undefined) }))
    }

    if (isActionOf(createDigitalGiveawayUrl, action)) {
        const { flightCamera, dealerId} = action.payload
        const vehicleCodeValue = vehicleCodeSelector(getState())
        dispatch(addPendingRequest(API_URL_DIGITAL_GIVEAWAY_LINK, { causedBy: createDigitalGiveawayUrl(undefined) }))
        dispatch(loadDigitalGiveawayUrl(
            {
                vehicleCode: vehicleCodeValue,
                withFlightCamera: flightCamera,
                dealerId: dealerId,
            },
            {
                onSuccess: digitalGiveawayUrlIsLoaded,
                onFailure: digitalGiveawayUrlCreationFailed,
            },
        ))
    }

    if (isActionOf(loadDigitalGiveawayUrl, action)) {
        apiRequest(
            {
                options: {
                    url: getDigitalGiveawayLinkUrl(action.payload.vehicleCode, action.payload.withFlightCamera, action.payload.dealerId),
                    method: 'GET',
                },
                asyncActions: {
                    request: loadDigitalGiveawayUrlAsync.request,
                    success: action.meta?.onSuccess || loadDigitalGiveawayUrlAsync.success,
                    failure: action.meta?.onFailure || loadDigitalGiveawayUrlAsync.failure,
                },
                causedBy: action,
            },
            dispatch,
        )
    }

    if (isActionOf(digitalGiveawayUrlIsLoaded, action)) {
        dispatch(setDigitalGiveawayUrl(action.payload.url))
        dispatch(removePendingRequest(API_URL_DIGITAL_GIVEAWAY_LINK, { causedBy: createDigitalGiveawayUrl(undefined) }))
    }

    if (isActionOf(digitalGiveawayUrlCreationFailed, action)) {
        dispatch(removePendingRequest(API_URL_VEHICLECODE, { causedBy: openDigitalGiveawayUrl(undefined) }))
        dispatch(removePendingRequest(API_URL_DIGITAL_GIVEAWAY_LINK, { causedBy: createDigitalGiveawayUrl(undefined) }))

        console.error(action.payload)

        const message = action.payload.message !== "BAD_REQUEST" && action.payload.message !== "INTERNAL_SERVER_ERROR"
            ? action.payload.message
            : 'error.digitalGiveaway'

        dispatch(createNotification({
            type: 'error',
            message: message,
        }))
    }
}

export default digitalGiveawayMiddleware
