import { connect, MapStateToProps } from 'react-redux'
import navLinksSelector from '../../redux/selectors/navLinksSelector'
import { Props } from './Navigation'
import abortUrlSelector from '../../redux/selectors/url/abortUrlSelector'
import orderButtonsAreActiveSelector from "../../redux/selectors/feature-scope/orderButtonsAreActiveSelector"

type StateProps = Pick<Props, 'navLinks' | 'abortUrl' | 'orderButtonsAreActive'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    navLinks: navLinksSelector(state),
    orderButtonsAreActive: orderButtonsAreActiveSelector(state),
    abortUrl: abortUrlSelector(state),
})

export default connect(mapStateToProps)
