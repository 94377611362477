import { CarVisualization, MiwiAppServiceProvider } from '@audi/miwi-feature-service'
import { CustomFeatureAppRenderingParams, FeatureAppLoader } from '@feature-hub/react'
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import MiwiIcon from '../../assets/media/icons/miwi-button.svg'
import config from '../../config'
import Visualization from '../../entities/Visualization'
import MiwiFeatureServiceContext from '../../feature-app/MiwiFeatureServiceContext'
import useTranslation from '../../hooks/useTranslation'
import navigationStyle from '../navigation/navigation.css'
import style from './miwiFeatureAppLoader.css'
import filterRenderImagesByOrdering from "../../redux/selectors/render-image/helper/filterRenderImages"

export type Props = {
    isMiwiEnabled: boolean
    language: string | null
    visualization: Visualization
    consumptionData: string[]
    brand: string
    clientInformation: string
    renderImagesOrdering: string[]
}
const normalizeConsumptionData = (consumptionData: string[]): string[] => {
    return consumptionData ?
        consumptionData
            .map(data => data.replace(/<sub>(.*?)<\/sub>/g, "2").trim())
            .map(data => data.replace(/[\n\t]/g, ''))
        :
        []
}

const normalizeUrl = (url: string): string => {
    if (url && url.startsWith('http://')) {
        return url.replace('http://', 'https://')
    }
    return url
}

const MiwiFeatureAppLoader: FunctionComponent<Props> = (props) => {
    const { isMiwiEnabled, language, visualization, consumptionData, brand, clientInformation, renderImagesOrdering } = props
    const miwiFS = React.useContext<MiwiAppServiceProvider>(MiwiFeatureServiceContext)
    const { t } = useTranslation()

    const [loadingError, setLoadingError] = useState(false)

    useEffect(() => {
        if (miwiFS && miwiFS.getMiwiService()) {
            const visualizationForMiwi: CarVisualization = {
                ...{
                    ...visualization,
                    renderImages: [],
                },
            }

            if (visualizationForMiwi.renderImages) {
                const filteredRenderImages = filterRenderImagesByOrdering(visualization.renderImages, renderImagesOrdering)

                visualizationForMiwi.renderImages = filteredRenderImages.map((renderImage) => {
                    const imageUrl = renderImage.renderDetails?.find(renderDetail => renderDetail.size === 3840).url
                    const normalizedUrl = normalizeUrl(imageUrl)
                    return {
                        id: renderImage.id,
                        urls: {
                            1920: normalizedUrl,
                            hd: normalizedUrl,
                        },
                        isActive: renderImage.isActive,
                    }
                })
            }

            visualizationForMiwi.consumptionData = normalizeConsumptionData(consumptionData)

            miwiFS.getMiwiService().updateVisualization(visualizationForMiwi)
        }
    }, [miwiFS, visualization, consumptionData, renderImagesOrdering])

    if (!isMiwiEnabled || loadingError) {
        return null
    }

    const backendSwitcherEnabled = config.MIWI_FEATURE_BACKEND_SWITCHER === 'enabled'

    const miwiAppConfig = {
        brand,
        lang: language,
        librarySrc: config.MIWI_FEATURE_LIBRARY_URL,
        visualization,
        backendSwitcher: backendSwitcherEnabled,
        clientInformation,
    }

    const triggerMiwiButtonClick = (event): void => {
        event.target.closest('button').parentNode.querySelector('#miwi-app-button').click()
    }

    const children = (childProps: CustomFeatureAppRenderingParams): ReactElement => (
        <div className={navigationStyle.link}>
            {childProps.featureAppNode}
            <button
                className={`${navigationStyle.link} ${style.miwiButtonWrapper} ${navigationStyle.miwibutton} ${
                    childProps.loading ? style.miwiButtonWrapperLoading : ''
                }`}
                onClick={triggerMiwiButtonClick}
                type="button">
                <MiwiIcon className={navigationStyle.icon} />
                <div className={navigationStyle.label}>{t('miwi.button-label')}</div>
                <div className={`${navigationStyle.trafficLight} ${style.trafficLight}`} />
            </button>
        </div>
    )

    const handleLoadingError = (error): void => {
        console.error(error)
        setLoadingError(true)
    }

    return (
        <>
            <FeatureAppLoader
                featureAppId="dasburo:dcc-app@dasburo:miwi-app"
                baseUrl={config.MIWI_FEATURE_APP_URL}
                src="/MiwiFeatureApp.umd.js"
                css={[{href: '/MiwiFeatureApp.css'}]}
                onError={handleLoadingError}
                config={miwiAppConfig}
            >
                {children}
            </FeatureAppLoader>
        </>
    )
}

export default MiwiFeatureAppLoader
