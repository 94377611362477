import React, {FunctionComponent, useEffect, useState} from 'react'
import style from './buildabilitySwitcherButton.css'
import {BuildabilityType} from '../../entities/BuildabilityType'

export type Props = {
    changeBuildabilityType: Function
    defaultBuildabilityType: BuildabilityType
    availableBuildabilityTypes: BuildabilityType[]
    currentBuildabilityType: BuildabilityType
}

const BuildabilitySwitcherButton: FunctionComponent<Props> = (props) => {
    const {
        changeBuildabilityType,
        defaultBuildabilityType,
        availableBuildabilityTypes,
        currentBuildabilityType,
    } = props
    const [buildabilityType, setBuildabilityType] = useState(currentBuildabilityType || defaultBuildabilityType)

    useEffect(() => {
        if (currentBuildabilityType) {
            setBuildabilityType(currentBuildabilityType)
        }
    }, [currentBuildabilityType])

    const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newBuildabilityType = event.target.value as BuildabilityType
        changeBuildabilityType(newBuildabilityType)
    }

    return (
        <>
            <select
                onChange={onChangeHandler}
                value={buildabilityType}
                className={style.dropdown}
            >
                {availableBuildabilityTypes.map((type) => (
                    <option key={type} value={type}>
                        {type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase())}
                    </option>
                ))}
            </select>
        </>
    )
}

export default BuildabilitySwitcherButton
